//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface ICommissioningDetailsVM extends ICommissioningVM
{
  data: any;
}
export interface ICommissioningVM
{
  id: string;
  displayName: string;
  customerId: string;
  customerName: string;
  created: any;
  createdByName: string;
  ibnDate: any;
  documentURL: string;
  csvUrl: string;
  warmtepompType: string;
}
export interface IContactVM
{
  id: string;
  displayName: string;
  firstname: string;
  insertion: string;
  lastname: string;
  phone: string;
  customer: ICustomerVM;
  role: IRoleVM;
  firstLoginTime: any;
}
export interface ICustomerVM
{
  id: string;
  displayName: string;
  parentCustomerId: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  website: string;
}
export interface IGetRequestsResponseVM
{
  requests: IRequestVM[];
}
export interface IGetSettingsResponseVM
{
  isDebug: boolean;
}
export interface IGetUserProfileResponseVM
{
  value: IContactVM;
}
export interface ISubmitFormRequestVM<TForm>
{
  form: TForm;
  metadata: { [key:string]: any };
}
export interface ISubmitFormRequestObjectVM extends ISubmitFormRequestVM<any>
{
}
export interface IRequestVM
{
  id: string;
  displayName: string;
  status: string;
  customerId: string;
  customerName: string;
  created: any;
  createdByName: string;
  documentURL: string;
  documentUrls1: string[];
  documentUrls2: string[];
  documentUrls3: string[];
  documentUrls4: string[];
  documentUrls5: string[];
  documentUrls6: string[];
  documentUrls7: string[];
  number: string;
  referenceNumber: string;
}
export interface IRoleVM
{
  id: string;
  displayName: string;
  requestPriceIndication: boolean;
  showPriceIndicationOverview: boolean;
  showAllPriceIndications: boolean;
  showCommissioning: boolean;
  showCommissioningOverview: boolean;
  showComplaint: boolean;
  showComplaintOverview: boolean;
  showQuoteSolutions: boolean;
  showQuotes: boolean;
  showMultiZoneVerdelerConfigurator: boolean;
  showKnowledgeBase: boolean;
  showKnowledgeBaseAdvanced: boolean;
  showKnowledgeBaseBasic: boolean;
  isNathanEmployee: boolean;
  showBrlTool: boolean;
}
export interface IGetCommissioningResponseVM
{
  value: ICommissioningDetailsVM;
}
export interface IGetCommissioningsResponseVM
{
  commissionings: ICommissioningVM[];
}
export interface IGetComplaintResponseVM
{
  complaint: IRequestVM;
}
export interface IGetComplaintsResponseVM
{
  complaints: IRequestVM[];
}
export interface IGetKnowledgeBaseCredentialsResponseVM
{
  userName: string;
  password: string;
}
export interface IGetListsResponseVM
{
  complaintStatusList: string[];
}
export interface IParseDataFileResponseVM
{
  fileName: string;
  data: { [key:string]: string };
  errors: string[];
  generatedFileId: string | undefined;
}
export interface ISaveTemporaryFileResponseVM
{
  fileId: string;
  fileName: string;
  originalFileName: string;
}
export interface ISubmitCommissioningsResponseVM
{
  id: string;
  fileId: string;
  pdfUrl: string;
  csvUrl: string;
}
export interface ISubmitFormCommissioningRequestVM extends ISubmitFormRequestObjectVM
{
}
export interface ISubmitFormComplaintRequestVM extends ISubmitFormRequestObjectVM
{
}
export interface ISubmitFormComplaintResponseVM
{
  id: string;
  displayName: string;
  number: string;
}
export interface IUploadedFile
{
  id: string;
  extension: string;
  fileName: string;
  storageFileName: string;
  fieldName: string;
}
export interface IUploadComplaintDocumentResponseVM
{
  files: IUploadedFile[];
}
export enum HeatPumpTypeEnum { 
  none = 0, 
  groundBound = 1, 
  airWater = 2
}
export enum HeatPumpFunctionEnum { 
  none = 0, 
  heat = 1, 
  heatAndCool = 2
}
export enum SanitationEnum { 
  none = 0, 
  shower = 1, 
  showerAndBath = 2
}
export enum FloorHeatingTypeEnum { 
  none = 0, 
  constructionNets = 1, 
  tacker = 2
}
export enum PostAdjustmentTypeEnum { 
  none = 0, 
  sMatrixBase = 1, 
  wavePlus = 2, 
  masterMasterAlphaHome = 3
}
export enum TypeOfHouseEnum { 
  none = 0, 
  newBuilding = 1, 
  renovationFloorHeating = 2, 
  renovationRadiators = 3, 
  existingNotIsolated = 4
}
export interface IFormA extends IAMijnNathanForm
{
  projectName: string;
  projectDescription: string;
  projectPostalCode: string;
  projectHouseNumber: string;
  heatPumpType: HeatPumpTypeEnum;
  heatPumpFunction: HeatPumpFunctionEnum;
  powerNeededInKW: number;
  numberOfPeopleInHome: string;
  sanitation: SanitationEnum;
  floorHeatingType: FloorHeatingTypeEnum;
  postAdjustmentType: PostAdjustmentTypeEnum;
  basementLivingArea: number;
  groundFloorLivingArea: number;
  firstFloorLivingArea: number;
  secondFloorLivingArea: number;
  thirdFloorLivingArea: number;
  manualHeatingChoice: boolean;
  heatLossCalculation: boolean;
  floorArea: number;
  typeOfHouse: TypeOfHouseEnum;
}
export interface IAMijnNathanForm
{
  companyName: string;
  companyPostalCode: string;
  companyAddress: string;
  companyCity: string;
  companyInstallerCode: string;
  givenName: string;
  initials: string;
  surname: string;
  gender: string;
  emailAddress: string;
  phoneNumber: string;
}
export interface ITemporaryFileInfo
{
  id: string;
  name: string;
  originalName: string;
}
